import _ from "lodash";

const institutions = [
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/6.png",
    alt: "",
    projectname: "Delhi Public School, Faridabad",
    link: "/projects/dpsfaridabad/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/dpspatna/hex.png",
    alt: "",
    projectname: "Delhi Public School, Patna",
    link: "/projects/dpspatna/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/dpsnoida/pic1.png",
    alt: "",
    projectname: "Delhi Public School, Noida",
    link: "/projects/dpsnoida/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/dpsrkpuram/hex.png",
    alt: "",
    projectname: "Delhi Public School, R.K. Puram",
    link: "/projects/dpsrkpuram/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/dpsvasantkunj/hex.png",
    alt: "",
    projectname: "Delhi Public School, Vasant Kunj",
    link: "/projects/dpsvasantkunj/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/VPSSSahmednagar/hex.png",
    alt: "",
    projectname: "Vikhe Patil Senior Secondary School, Ahmednagar",
    link: "/projects/vpsssahmednagar/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/VPSSSahmednagar/hex.png",
    alt: "",
    projectname: "Vikhe Patil Senior Secondary School, Pune",
    link: "/projects/vpssspune/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/ratnadeepinstitute/hex.png",
    alt: "",
    projectname: "Ratnadeep Institute of Medical Sciences",
    link: "/projects/ratnadeepinstituteofmedicalsciences/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/dpsdwarka/3.png",
    alt: "",
    projectname: "Delhi Public School, Dwarka",
    link: "/projects/dpsdwarka/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/dpsgreaternoida/hex.png",
    alt: "",
    projectname: "Delhi Public School, Greater Noida",
    link: "/projects/dpsgreaternoida/",
  },

  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/cavokaviation/1.png",
    alt: "",
    projectname: "Cavok Aviation",
    link: "/projects/cavokaviation/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/iasacademy/hex.png",
    alt: "",
    projectname: "IAS Academy",
    link: "/projects/iasacademy/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/pravaramedicaltrust/1.png",
    alt: "",
    projectname: "Pravara Medical Trust, Loni, Maharashtra",
    link: "/projects/pravaramedicaltrustloni/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/hex.png",
    alt: "",
    projectname: "Vikhe Patil Foundation",
    link: "/projects/vikhepatilfoundation/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/dpsrohini/4.png",
    alt: "",
    projectname: "Delhi Public School, Rohini",
    link: "/projects/dpsrohini/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/sgtuniversity/hex.png",
    alt: "",
    projectname: "SGT University",
    link: "/projects/sgtuniversity/",
  },
];

const commercial = [
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/orchidinfrastructuredevelopers/hex.png",
    alt: "",
    projectname: "Orchid Infrastructure Developers",
    link: "/projects/orchidinfrastructuredevelopers/",
  },

  //TODO
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/1.png",
    alt: "",
    projectname: "Arravali Hair Transplant Clinic",
    link: "/projects/arravalihairtransplantclinic/",
  },
  //TODO
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/amolikgroup/pic3.png",
    alt: "",
    projectname: "Amolik Group",
    link: "/projects/amolikgroup/",
  },
  //TODO
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/fssai/pic2.png",
    alt: "",
    projectname: "FSSAI",
    link: "/projects/fssai/",
  },
  //TODO
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/investmentcorporationchennai/pic2.png",
    alt: "",
    projectname: "Investment Corporation, Chennai",
    link: "/projects/investmentcorporationchennai/",
  },
  //TODO
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/ceoofficepmtloni/1.png",
    alt: "",
    projectname: "CEO Office, PMT Loni",
    link: "/projects/ceoofficepmtloni/",
  },
  //TODO
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/emedelhicantt/1.png",
    alt: "",
    projectname: "EME Officers Mess, Delhi Cantt",
    link: "/projects/emeofficersmessdelhicantt/",
  },
  //TODO
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/nailritual/11.png",
    alt: "",
    projectname: "Nail Ritual, Gurgaon",
    link: "/projects/nailritualgurgaon/",
  },
];

const residential = [
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/atravilla/1.png",
    alt: "",
    projectname: "A.T.R.A Villa, Mashobra",
    link: "/projects/atravillamashobra/",
  },

  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/arorahouse/1.png",
    alt: "",
    projectname: "Arora House, bikaner",
    link: "/projects/arorahousebikaner/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/balanahouse/interiors/5.png",
    alt: "",
    projectname: "Balana House, Jaipur",
    link: "/projects/balanahousejaipur/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorashokvihar/1.png",
    alt: "",
    projectname: "Builders Floor, Ashok Vihar, Delhi",
    link: "/projects/buildersfloorashokvihar/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/1.png",
    alt: "",
    projectname: "Builders Floor, Gujranwala, Delhi",
    link: "/projects/buildersfloorgujrawala/",
  },

  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloormodeltown/4.png",
    alt: "",
    projectname: "Builders Floor, Model Town",
    link: "/projects/buildersfloormodeltown/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/1.png",
    alt: "",
    projectname: "Builders Floor, Northex, Delhi",
    link: "/projects/buildersfloornorthex/",
  },

  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/twinhouse/1.png",
    alt: "",
    projectname: "Twin House, Bikaner",
    link: "/projects/twinhousebikaner/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/instituteofliverandbillarysciences/3.png",
    alt: "",
    projectname: "Institute of Liver and Billary Sciences, New Delhi",
    link: "/projects/instituteofliverandbillarysciencesnewdelhi/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/pravaramedicaltrust/1.png",
    alt: "",
    projectname: "Pravara Medical Trust, Loni, Maharashtra",
    link: "/projects/pravaramedicaltrustlonimaharashtra/",
  },
  // {
  //   imgSrc:
  //     "https://archdesignsstudio.com/assets/projects/vikhepatilgirlshostel/hex.png",
  //   alt: "",
  //   projectname: "Vikhe Patil Foundation Girls Hostel",
  //   link: "/projects/vikhepatilfoundationgirlshostel/",
  // },
  // {
  //   imgSrc:
  //     "https://archdesignsstudio.com/assets/projects/vikhepatilbhostel/hex.png",
  //   alt: "",
  //   projectname: "Vikhe Patil Foundation Boys Hostel",
  //   link: "/projects/vikhepatilfoundationboyshostel/",
  // },
];

const masterplanning = [
  // {
  //   imgSrc:
  //     "https://archdesignsstudio.com/assets/projects/kandytransitterminal/hex.png",
  //   alt: "",
  //   projectname: "Kandy Multimodal Transit Terminal",
  //   link: "/projects/kandymultimodaltransitterminal/",
  // },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/sgtuniversity/hex.png",
    alt: "",
    projectname: "SGT University",
    link: "/projects/sgtuniversity/",
  },
];

const interiors = [
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/1.png",
    alt: "",
    projectname: "Arravali Hair Transplant Clinic",
    link: "/projects/arravalihairtransplantclinic/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/ceoofficepmtloni/1.png",
    alt: "",
    projectname: "CEO Office, PMT Loni",
    link: "/projects/ceoofficepmtloni/",
  },

  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/emedelhicantt/1.png",
    alt: "",
    projectname: "EME Officers Mess, Delhi Cantt",
    link: "/projects/emeofficersmessdelhicantt/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/nailritual/11.png",
    alt: "",
    projectname: "Nail Ritual, Gurgaon",
    link: "/projects/nailritualgurgaon/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/6.png",
    alt: "",
    projectname: "Delhi Public School, Faridabad",
    link: "/projects/dpsfaridabad/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/dpsgreaternoida/hex.png",
    alt: "",
    projectname: "Delhi Public School, Greater Noida",
    link: "/projects/dpsgreaternoida/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/dpsrohini/4.png",
    alt: "",
    projectname: "Delhi Public School, Rohini",
    link: "/projects/dpsrohini/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/iasacademy/hex.png",
    alt: "",
    projectname: "IAS Academy",
    link: "/projects/iasacademy/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/balanahouse/1.png",
    alt: "",
    projectname: "Balana House, Jaipur",
    link: "/projects/balanahousejaipur/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/riteshgupta/3.png",
    alt: "",
    projectname: "Ritesh Gupta, ATS Noida",
    link: "/projects/riteshguptaatsnoida/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/showflat/1.png",
    alt: "",
    projectname: "Show Flat, Bangalore",
    link: "/projects/showflatbangalore/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/varunagarwal/1.png",
    alt: "",
    projectname: "Varun Agrawal",
    link: "/projects/varunagrawal/",
  },
];

const landscape = [
  // {
  //   imgSrc:
  //     "https://archdesignsstudio.com/assets/projects/kandytransitterminal/hex.png",
  //   alt: "",
  //   projectname: "Kandy Multimodal Transit Terminal",
  //   link: "/projects/kandymultimodaltransitterminal/",
  // },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/itcgoa/1.png",
    alt: "",
    projectname: "ITC Goa",
    link: "/projects/itcgoa/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/dpsnoida/pic1.png",
    alt: "",
    projectname: "Delhi Public School, Noida",
    link: "/projects/dpsnoida/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/itcpehalgam/pic1.png",
    alt: "",
    projectname: "ITC Pahalgam",
    link: "/projects/itcpahalgam/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/1.png",
    alt: "",
    projectname: "ITC Rajkot",
    link: "/projects/itcrajkot/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects/itcsrinagar/pic2.png",
    alt: "",
    projectname: "ITC Srinagar",
    link: "/projects/itcsrinagar/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/krishnafarms/1.png",
    alt: "",
    projectname: "Krishna Farms",
    link: "/projects/krishnafarms/",
  },
  {
    imgSrc: "https://archdesignsstudio.com/assets/projects-re/mohanfarms/4.png",
    alt: "",
    projectname: "Mohan Farms, New Delhi",
    link: "/projects/mohanfarms/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/1.png",
    alt: "",
    projectname: "Motherson Corporate Park, Noida",
    link: "/projects/mothersoncorporateparknoida/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/1.png",
    alt: "",
    projectname: "Pasricha Farm House",
    link: "/projects/pasrichafarmhouse/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/tdimarketingoffice/pic1.png",
    alt: "",
    projectname: "TDI Marketing Office, Kundli",
    link: "/projects/tdimarketingofficekundli/",
  },
  {
    imgSrc:
      "https://archdesignsstudio.com/assets/projects/terracegarden/pic1.png",
    alt: "",
    projectname: "Terrace Gardens",
    link: "/projects/terracegardens/",
  },
];

const rest = [
  ...commercial,
  ...residential,
  ...landscape,
  ...interiors,
  ...masterplanning,
];

const sortedRest = _.sortBy(rest, [
  function (o) {
    return o.projectname;
  },
]);

const all = [...institutions, ...sortedRest];

const unique = new Set();
const distictAll = all.filter((entry) => {
  if (unique.has(entry.link)) {
    return false;
  }
  unique.add(entry.link);
  return true;
});

const data = {
  all: distictAll,
  institutions,
  commercial,
  residential,
  masterplanning,
  interiors,
  landscape,
};

export default data;
